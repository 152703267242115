<template>
  <div class="BuildingSelectMenu">
    <DotMenuItem
      v-if="canBeEdited"
      :text="mixWB('EDIT_BUILDING')"
      itemID="edit-building"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('CREATE_FLOOR')"
      itemID="create-floor"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      v-if="canBeDeleted"
      :text="mixWB('DELETE_BUILDING')"
      itemID="delete-building"
      color="red"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'

export default {
  name: 'BuildingSelectMenu',
  props: {
    canBeDeleted: {
      type: Boolean,
      default: true,
    },
    canBeEdited: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'menu-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
</style>
