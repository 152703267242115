<template>
  <div class="ScreeningUnitSelection">
    <div
      v-if="screeningUnitsCopy.buildings.length"
      class="BuildingList">
      <BuildingSelectItem
        v-for="building in screeningUnitsCopy.buildings"
        :key="building.id"
        :building="building"
        :floors="screeningUnitsCopy.floors"
        :isUnitAllowedToBeDeleted="isUnitAllowedToBeDeleted"
        :apartments="screeningUnitsCopy.apartments" />
    </div>

    <!-- Errors -->
    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('ADD_NEW_BUILDING')"
        outlined
        @button-click="onNewBuildingClick" />
    </div>

    <!-- Button -->
    <Button
      type="submit"
      :text="next.text"
      :isLoading="isButtonLoading"
      @button-click="onSaveClick" />

    <Dialog
      :isShowing="showNewBuildingDialog"
      :useComponent="NewBuildingForm"
      :componentProps="{
        buildingToEdit,
      }"
      @close="showNewBuildingDialog = false"
      @form-submit="onNewBuildingFormSubmit"
      @form-submit-edit="onNewBuildingFormSubmitEdit"/>

    <Dialog
      :isShowing="showNewFloorDialog"
      :useComponent="NewFloorForm"
      :componentProps="{
        building: buildingToEdit,
        floors: screeningUnitsCopy.floors,
        floorToEdit,
      }"
      @close="showNewFloorDialog = false"
      @form-submit="onNewFloorFormSubmit"
      @form-submit-edit="onNewFloorFormSubmitEdit"/>
  </div>
</template>

<script>
import BuildingSelectItem from '@/components/ScreeningItems/BuildingSelectItem.vue'
import Button from '@/components/Buttons/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewBuildingForm from '@/components/Forms/NewBuildingForm.vue'
import NewFloorForm from '@/components/Forms/NewFloorForm.vue'
import { Building } from '@/globals/javascript/models/units/Building'
import { Apartment } from '@/globals/javascript/models/units/Apartment'
import { Floor } from '@/globals/javascript/models/units/Floor'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'ScreeningUnitSelection',
  props: {
    loadSavedUnits: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      NewBuildingForm,
      NewFloorForm,
      showNewBuildingDialog: false,
      showNewFloorDialog: false,
      isButtonLoading: false,
      buildingToEdit: false,
      floorToEdit: false,
      changesDetected: false,
      errorText: '',
      screeningUnitsCopy: {
        buildings: [],
        floors: [],
        apartments: [],
      },
      newlyAddedUnits: [],
      newlyRemovedUnits: [],
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'currentScreeningData',
      'screeningUnits',
      'projectType',
      'builderFullName',
      'currentScreeningTenderDetails',
    ]),
    combinedCopiedList() {
      return this.screeningUnitsCopy.buildings.concat(
        this.screeningUnitsCopy.floors,
        this.screeningUnitsCopy.apartments,
      )
    },
    next() {
      if (this.currentScreeningData.isStarted) {
        return {
          text: this.mixWB('SAVE_CHANGES'),
          path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.projectType) {
        return {
          text: this.changesDetected ? this.mixWB('SAVE_CHANGES') : this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateProjectDetails', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.builderFullName && this.builderFullName !== '') {
        return {
          text: this.changesDetected ? this.mixWB('SAVE_CHANGES') : this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateBuilder', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.currentScreeningTenderDetails.selectedQuestionIDs.length) {
        return {
          text: this.changesDetected ? this.mixWB('SAVE_CHANGES') : this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateTenderDetails', params: { screeningID: this.currentScreening.id } },
        }
      }
      return {
        text: this.mixWB('SAVE_CHANGES'),
        path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
      }
    },
  },
  watch: {
    screeningUnits() {
      if (!this.isButtonLoading) {
        this.createCopyOfUnits()
      }
    },
  },
  methods: {
    isUnitAllowedToBeDeleted(unit) {
      if (unit.bbrID) {
        return false
      }

      if (!this.currentScreeningData.isStarted) {
        return true
      }

      if (this.newlyAddedUnits.includes(unit.id)) {
        return true
      }

      if (unit.isSelected) {
        return false
      }

      // If any floors are selected we should deny to delete a building
      // We do not do any check for floors and apartments, as it should only be done for childdren
      // and floors cannot have apartments as children only if in BBR.
      if (unit instanceof Building) {
        const selectedFloorInTree = this.screeningUnitsCopy.floors.find(
          (floor) => floor.data.buildingID === unit.id && floor.isSelected,
        )

        if (selectedFloorInTree) {
          return false
        }
      }

      return true
    },
    isUnitAllowedToBeUpdated(unit) {
      if (!this.currentScreeningData.isStarted) {
        return true
      }

      if (!unit.isSelected) {
        return true
      }

      if (unit.isSelected && this.newlyAddedUnits.includes(unit.id)) {
        return true
      }

      return false
    },
    onUnitClick(unitID, list) {
      const unit = list.find((x) => x.id === unitID)

      const isAllowedToUpdate = this.isUnitAllowedToBeUpdated(unit)
      if (!isAllowedToUpdate) {
        return
      }

      unit.isSelected = !unit.isSelected
      this.newlyAddedUnits.push(unit.id)

      this.changesDetected = true
    },
    onBuildingClick(buildingID) {
      this.onUnitClick(buildingID, this.screeningUnitsCopy.buildings)
    },
    onFloorClick(floorID) {
      this.onUnitClick(floorID, this.screeningUnitsCopy.floors)
    },
    onApartmentClick(apartmentID) {
      this.onUnitClick(apartmentID, this.screeningUnitsCopy.apartments)
    },
    onBuildingEditClick(buildingID) {
      const building = this.screeningUnitsCopy.buildings.find((x) => x.id === buildingID)
      this.buildingToEdit = building

      this.showNewBuildingDialog = true
    },
    onBuildingDeleteClick(buildingID) {
      const building = this.screeningUnitsCopy.buildings.find((x) => x.id === buildingID)
      const orphans = this.combinedCopiedList.find((x) => x.data.buildingID === buildingID)

      if (orphans) {
        const answer = window.confirm(this.mixWB('CONFIRM_REMOVE_UNIT', [building.getTitle()]))
        if (!answer) {
          return
        }
      }

      this.screeningUnitsCopy.buildings = this.screeningUnitsCopy.buildings.filter(
        (b) => b.id !== buildingID,
      )

      this.onDeleteCleanupOrphans(building)
      this.changesDetected = true
    },
    onNewBuildingClick() {
      this.buildingToEdit = false
      this.showNewBuildingDialog = true
    },
    onNewBuildingFormSubmit(data) {
      this.showNewBuildingDialog = false

      const building = new Building({
        isSelected: true,
        manualData: {
          ...data,
        },
      })

      this.screeningUnitsCopy.buildings.push(building)
      this.newlyAddedUnits.push(building.id)
      this.changesDetected = true
    },
    onNewBuildingFormSubmitEdit(data) {
      this.showNewBuildingDialog = false
      const building = this.buildingToEdit

      Object.keys(data).forEach((key) => {
        building.manualData[key] = data[key]
      })

      this.changesDetected = true
    },
    onFloorEditClick(floorID) {
      const floor = this.screeningUnitsCopy.floors.find((x) => x.id === floorID)
      const building = this.screeningUnitsCopy.buildings.find((x) => x.id === floor.data.buildingID)
      this.floorToEdit = floor
      this.buildingToEdit = building

      this.showNewFloorDialog = true
    },
    onNewFloorClick(buildingID) {
      const building = this.screeningUnitsCopy.buildings.find((x) => x.id === buildingID)
      this.buildingToEdit = building
      this.floorToEdit = false
      this.showNewFloorDialog = true
    },
    onFloorDeleteClick(floorID) {
      const floor = this.screeningUnitsCopy.floors.find((x) => x.id === floorID)
      const orphans = this.combinedCopiedList.find((x) => x.data.floorID === floorID)
      if (orphans) {
        const answer = window.confirm(this.mixWB('CONFIRM_REMOVE_UNIT', [floor.getTitle()]))
        if (!answer) {
          return
        }
      }

      this.screeningUnitsCopy.floors = this.screeningUnitsCopy.floors.filter(
        (item) => item.id !== floorID,
      )
      this.onDeleteCleanupOrphans(floor)
      this.changesDetected = true
    },
    onNewFloorFormSubmit(data) {
      const buildingID = this.buildingToEdit.id
      this.showNewFloorDialog = false
      this.buildingToEdit = false

      const floor = new Floor({
        isSelected: true,
        data: {
          numericFloorValue: Number(data.numericFloorValue),
          buildingID,
        },
      })

      this.screeningUnitsCopy.floors.push(floor)
      this.newlyAddedUnits.push(floor.id)
      this.changesDetected = true
    },
    onNewFloorFormSubmitEdit(data) {
      this.showNewFloorDialog = false
      const floor = this.floorToEdit

      floor.data.numericFloorValue = Number(data.numericFloorValue)

      this.changesDetected = true
    },
    onDeleteCleanupOrphans(unit) {
      this.newlyRemovedUnits.push(unit)
      // Cleanup floors and apartments which are connected to this unit
      if (unit instanceof Building) {
        const removedFloors = this.screeningUnitsCopy.floors.filter(
          (floor) => floor.data.buildingID === unit.id,
        )
        this.screeningUnitsCopy.floors = this.screeningUnitsCopy.floors.filter(
          (floor) => !removedFloors.includes(floor),
        )
        this.newlyRemovedUnits = this.newlyRemovedUnits.concat(removedFloors)
      }

      const removedApartments = this.screeningUnitsCopy.apartments.filter(
        (apartment) => apartment.data.buildingID === unit.id || apartment.data.floorID === unit.id,
      )
      this.screeningUnitsCopy.apartments = this.screeningUnitsCopy.apartments.filter(
        (apartment) => !removedApartments.includes(apartment),
      )
      this.newlyRemovedUnits = this.newlyRemovedUnits.concat(removedApartments)
    },
    onSaveClick() {
      const firstSelectedUnit = this.combinedCopiedList.find((x) => x.isSelected)
      if (!firstSelectedUnit) {
        this.errorText = this.mixWB('CHOOSE_AT_LEAST_ONE_UNIT_TEXT')
        return
      }
      this.isButtonLoading = true

      this.$store.dispatch('saveScreeningUnits', {
        combinedList: this.combinedCopiedList,
      })
      this.$store.dispatch('removeScreeningUnits', {
        removedUnits: this.newlyRemovedUnits,
        updateScreening: false,
      })

      setTimeout(() => {
        this.$router.push(this.next.path)
      }, 250)
    },
    createCopyOfUnits() {
      const buildingsToShow = this.screeningUnits.buildings.filter((building) => {
        if (!building.bbrID) {
          return building
        }

        if (building.bbrData.ObjStatus !== 3) {
          return building
        }

        return false
      })
      this.screeningUnitsCopy.buildings = buildingsToShow.map(
        (building) => new Building({
          ...building,
        }),
      )
      this.screeningUnitsCopy.floors = this.screeningUnits.floors.map(
        (floor) => new Floor({
          ...floor,
        }),
      )
      this.screeningUnitsCopy.apartments = this.screeningUnits.apartments.map(
        (apartment) => new Apartment({
          ...apartment,
        }),
      )
    },
  },
  components: {
    BuildingSelectItem,
    Button,
    Dialog,
  },
  created() {
    this.createCopyOfUnits()

    EventBus.$on('building-click', this.onBuildingClick)
    EventBus.$on('floor-click', this.onFloorClick)
    EventBus.$on('apartment-click', this.onApartmentClick)
    EventBus.$on('building-edit-click', this.onBuildingEditClick)
    EventBus.$on('building-delete-click', this.onBuildingDeleteClick)
    EventBus.$on('building-create-floor-click', this.onNewFloorClick)
    EventBus.$on('floor-edit-click', this.onFloorEditClick)
    EventBus.$on('floor-delete-click', this.onFloorDeleteClick)
  },
  destroyed() {
    EventBus.$off('building-click', this.onBuildingClick)
    EventBus.$off('floor-click', this.onFloorClick)
    EventBus.$off('apartment-click', this.onApartmentClick)
    EventBus.$off('building-edit-click', this.onBuildingEditClick)
    EventBus.$off('building-delete-click', this.onBuildingDeleteClick)
    EventBus.$off('building-create-floor-click', this.onNewFloorClick)
    EventBus.$off('floor-edit-click', this.onFloorEditClick)
    EventBus.$off('floor-delete-click', this.onFloorDeleteClick)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningUnitSelection
    margin-bottom 20px

  .BuildingList
    margin-bottom 20px

  .ErrorText
    display block
    margin-top -10px
    margin-bottom 10px
    color $color_error
    font-size 0.875rem

  .ButtonWrap
    margin-bottom 10px
</style>
