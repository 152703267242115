<template>
  <div class="CadastralMapAutoUpload">
    <!-- Status -->
    <div class="MapUploadStatus">
      <span v-if="cadastralMapUploadStatus === 'initialising'">
        {{ mixWB('GENERATING_CADASTRAL_MAP') }}...
      </span>
      <span v-if="cadastralMapUploadStatus === 'uploading'">
        {{ mixWB('CADASTRAL_MAP_IS_BEING_UPLOADED_YOU_CAN_NOW_CONTINUE') }}
      </span>
      <span v-if="cadastralMapUploadStatus === 'uploaded'">
        {{ mixWB('CADASTRAL_MAP_IS_UPLOADED') }}
      </span>
    </div>

    <!-- Map -->
    <div class="CadastralMapWrap">
      <div class="PaddingTop"/>
      <div
        v-if="isMapLoading"
        class="ProgressWrap">
        <Circular />
      </div>
      <div
        v-if="mapFailed"
        class="MapFailed">
        <span>{{ mixWB('NOT_ABLE_TO_LOAD_THE_MAP') }}</span>
      </div>
      <div
        class="Inner"
        v-if="!isMapLoading && addressPoint.length">
        <CadastralMap
          :addressPoint="addressPoint"
          :buildingPoints="buildingPoints"
          :exportMapAsImage="uploadCadastralMap"
          :cadastralMapUploadStatus="cadastralMapUploadStatus"
          @image-exported="onImageExported" />
      </div>
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'
import CadastralMap from '@/components/CadastralMap.vue'
import { prepareImage } from '@/globals/javascript/_util/images'
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/browser'
import EventBus from '@/EventBus'

export default {
  name: 'CadastralMapAutoUpload',
  data() {
    return {
      buildingPoints: [],
      addressPoint: [],
      isMapLoading: true,
      mapFailed: false,
      uploadCadastralMap: false,
      mapUploadPath: '',
      mapImageUploadStatus: '',
    }
  },
  computed: {
    ...mapGetters([
      'screeningUnits',
      'combinedListOfUnits',
      'metaData',
      'addressImages',
      'imageUploadFolder',
    ]),
    cadastralMapUploadStatus() {
      if (this.mapImageUploadStatus === 'uploading') {
        return 'uploading'
      }

      if (this.addressImages?.overview?.cadastralMap) {
        return 'uploaded'
      }

      if (this.mapFailed) {
        return 'failed'
      }

      return 'initialising'
    },
  },
  methods: {
    getBasePath() {
      return `${ this.imageUploadFolder }AddressImages/Overview/`
    },
    onImageExported({ base64URL }) {
      this.mapImageUploadStatus = 'uploading'

      // Create image object
      const imageObject = prepareImage({
        basePath: this.getBasePath(),
        fileExtension: 'jpg',
        postFix: 'cadastral-map',
        minSize: 400,
      })

      // Store path
      this.mapUploadPath = imageObject.base.path

      // Upload image
      this.$store.dispatch('uploadImage', { imageObject, base64URL, place: this.mixWB('SELECT_BUILDINGS') })

      // Save image path
      this.$store.dispatch('setAddressImages', {
        imageList: [imageObject],
        place: 'overview',
        imageType: 'cadastralMap',
      })
    },
    onImageUploadStarted({ path }) {
      if (path !== this.mapUploadPath) {
        return
      }

      this.mapImageUploadStatus = 'uploading'
    },
    onImageUploaded({ path }) {
      if (path !== this.mapUploadPath) {
        return
      }

      this.mapImageUploadStatus = 'uploaded'
    },
    checkToUploadCadastralMap() {
      if (this.addressImages?.overview?.cadastralMap) {
        return
      }

      this.uploadCadastralMap = true
    },
    async findAddressGeoDataOnLoad() {
      const kftoken = 'd12107f70a3ee93153f313c7c502169a'

      // Find building points
      this.screeningUnits.buildings.forEach((building) => {
        if (
          building.bbrData?.bygningspunkt?.KoorNord
          && building.bbrData?.bygningspunkt?.KoorOest
        ) {
          this.buildingPoints.push({
            name: `B${ building.bbrData.Bygningsnr }`,
            point: [
              building.bbrData.bygningspunkt.KoorOest,
              building.bbrData.bygningspunkt.KoorNord,
            ],
          })
        }
      })

      // Find address point
      const addressGeoData = await this.$http.get('https://services.kortforsyningen.dk/Geosearch', {
        params: {
          search: this.metaData.address.tekst,
          resources: 'adresser', // the resource to search within - check valid resources on https://kortforsyningen.dk/indhold/geonoegler-geosearch
          token: kftoken,
        },
      })

      this.addressPoint.push(addressGeoData.data.data[0].x)
      this.addressPoint.push(addressGeoData.data.data[0].y)

      this.isMapLoading = false
    },
  },
  components: {
    Circular,
    CadastralMap,
  },
  created() {
    this.checkToUploadCadastralMap()
    let checkCounter = 0
    let numberOfUnitsFound = 0
    const waitForAllUnits = setInterval(() => {
      if (checkCounter < 10) {
        checkCounter += 1
        if (!this.combinedListOfUnits.length) {
          return
        }
        if (this.combinedListOfUnits.length > numberOfUnitsFound) {
          numberOfUnitsFound = this.combinedListOfUnits.length
          return
        }
      }

      clearInterval(waitForAllUnits)

      this.findAddressGeoDataOnLoad()
        .catch((err) => {
          this.isMapLoading = false
          this.mapFailed = true

          Sentry.captureException(err)
        })
    }, 250)
  },
  mounted() {
    EventBus.$on('image-upload-started', this.onImageUploadStarted)
    EventBus.$on('image-uploaded', this.onImageUploaded)
  },
  destroyed() {
    EventBus.$off('image-upload-started', this.onImageUploadStarted)
    EventBus.$off('image-uploaded', this.onImageUploaded)
  },
}
</script>

<style lang="stylus" scoped>
  .MapUploadStatus
    text-align center
    margin-bottom 10px
    span
      color $color_grey

  .CadastralMapWrap
    position relative
    width 100%
    border 1px solid $color_grey_lighter
    max-width 400px
    margin 0 auto
    .PaddingTop
      padding-top 100%
    .ProgressWrap
      box(100%)
      position absolute
      top 0
      left 0
    .MapFailed
      box(100%)
      position absolute
      top 0
      left 0
      flex-center-children()
      span
        text-align center
    .Inner
      position absolute
      top 0
      left 0
      box(100%)
</style>
