<template>
  <div
    class="GetBBRData">
    <div class="LoadingWrap">
      <Circular size="medium" />
    </div>
    <span>{{ mixWB('GETTING_BBR_INFO') }}</span>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'
import { Building } from '@/globals/javascript/models/units/Building'
import { Apartment } from '@/globals/javascript/models/units/Apartment'
import { Floor } from '@/globals/javascript/models/units/Floor'
import { mapGetters } from 'vuex'

export default {
  name: 'GetBBRData',
  computed: {
    ...mapGetters([
      'metaData',
    ]),
  },
  methods: {
    getNumericFloorValue(floor) {
      if (floor === 'st') {
        return 0
      }
      if (floor === 'kl') {
        return -1
      }
      if (floor.includes('k')) {
        Number(floor.replace('k', '-'))
      }
      return Number(floor)
    },
    async getStartDataFromDAWA() {
      const addressID = this.metaData.address.data.id

      // Find buildings for an address
      const addressBuildingsPromise = this.$http.get('https://dawa.aws.dk/bbrlight/bygninger', {
        params: {
          adgangsadresseid: addressID,
        },
      })

      // Find entrances for an address
      const addressEntrancesPromise = this.$http.get('https://dawa.aws.dk/bbrlight/opgange', {
        params: {
          adgangsadresseid: addressID,
        },
      })

      // Find addressData for an address
      const addressDataPromise = this.$http.get('https://dawa.aws.dk/adresser', {
        params: {
          adgangsadresseid: addressID,
        },
      })

      const [addressBuildings, addressEntrances, addressData] = await Promise.all([
        addressBuildingsPromise,
        addressEntrancesPromise,
        addressDataPromise,
      ])

      return { addressBuildings, addressEntrances, addressData }
    },
    async getMissingBuildingsBasedOnEntrances(addressEntrances, allBBRBuildings) {
      if (!addressEntrances.data.length) {
        return allBBRBuildings
      }
      // Find missing buildings based on entrances
      // - Sometimes an access address is not having buildings, but will have entrances that will
      // then refer to buildings
      const uniqueAndMissingEntranceBuildingIDs = addressEntrances.data.reduce(
        (prev, entranceData) => {
          const existingBuilding = allBBRBuildings.find(
            (x) => x.Bygning_id === entranceData.Bygning_id,
          )
          if (existingBuilding) {
            return prev
          }
          if (prev.includes(entranceData.Bygning_id)) {
            return prev
          }
          prev.push(entranceData.Bygning_id)
          return prev
        },
        [],
      )

      const entranceBuildings = uniqueAndMissingEntranceBuildingIDs.map(async (buildingID) => {
        const buildingData = await this.$http.get('https://dawa.aws.dk/bbrlight/bygninger', {
          params: {
            id: buildingID,
          },
        })

        return buildingData.data[0]
      })

      const entranceBuildingsArray = await Promise.all(entranceBuildings)

      allBBRBuildings = allBBRBuildings.concat(entranceBuildingsArray)

      return allBBRBuildings
    },
    async getAllBBRBuildingsWithData(addressBuildings, addressEntrances) {
      let allBBRBuildings = addressBuildings.data.length
        ? addressBuildings.data
        : []

      // Get missing buildings from entrances
      allBBRBuildings = await this.getMissingBuildingsBasedOnEntrances(
        addressEntrances,
        allBBRBuildings,
      )

      // Generate buildings
      const buildingPromises = allBBRBuildings.map(async (buildingData) => {
        const building = new Building({
          bbrID: buildingData.Bygning_id,
          bbrData: buildingData,
        })

        // Set han entrance or hallway
        const entranceItem = addressEntrances.data.find(
          (x) => x.Bygning_id === buildingData.Bygning_id,
        )
        if (entranceItem) {
          building.data.hasEntranceOrHallway = true
        }

        // Add BBR data for floors for building
        const buildingFloors = await this.$http.get('https://dawa.aws.dk/bbrlight/etager', {
          params: {
            bygningsid: buildingData.Bygning_id,
          },
        })
        if (buildingFloors && buildingFloors.data && buildingFloors.data.length) {
          building.bbrFloors = buildingFloors.data
        }

        return building
      })

      allBBRBuildings = await Promise.all(buildingPromises)

      // Set building as main entrance
      // TODO: Make better logic for different cases
      const building = allBBRBuildings.find((x) => x.data.hasEntranceOrHallway)
      if (building) {
        building.data.isMainBuilding = true
      }

      return allBBRBuildings
    },
    getAllBBRApartments(addressData, allBBRBuildings) {
      const mainBuilding = allBBRBuildings.find((x) => x.data.isMainBuilding)

      const allBBRApartments = addressData.data.reduce((prev, apartmentData) => {
        if (!apartmentData.etage) {
          return prev
        }

        const apartment = new Apartment({
          bbrID: apartmentData.id,
          bbrData: apartmentData,
        })

        // Add apartment data
        apartment.data.buildingID = mainBuilding.id
        apartment.data.bbrAccessAddressID = apartmentData.adgangsadresse.id
        apartment.data.numericFloorValue = this.getNumericFloorValue(
          apartmentData.etage.toLowerCase(),
        )
        apartment.data.title = `${
          apartmentData.etage
        } ${
          apartmentData.dør || ''
        }`.toUpperCase().trim()

        prev.push(apartment)

        return prev
      }, [])

      return allBBRApartments
    },
    getFloorsBasedOnBBRBuildings(allBBRBuildings) {
      const floorsFromAllBuildings = allBBRBuildings.reduce((prev, building) => {
        if (!building.bbrFloors.length) {
          return prev
        }
        const singleBuildingFloors = building.bbrFloors.reduce((prev2, floorData) => {
          const floor = new Floor({
            bbrID: floorData.Etage_id,
            bbrData: floorData,
          })

          floor.data.buildingID = building.id
          floor.data.bbrBuildingID = floorData.Bygning_id
          floor.data.numericFloorValue = this.getNumericFloorValue(
            floorData.Etagebetegn.toLowerCase(),
          )

          prev2.push(floor)
          return prev2
        }, [])
        prev = prev.concat(singleBuildingFloors)
        return prev
      }, [])

      return floorsFromAllBuildings
    },
    getAllFloorsAndAssignApartmentsToFloors(
      allBBRBuildings,
      allBBRApartments,
      floorsFromBBRBuildings,
    ) {
      if (!allBBRApartments.length) {
        return floorsFromBBRBuildings
      }

      const mainBuilding = allBBRBuildings.find((x) => x.data.isMainBuilding)

      const allBBRFloors = allBBRApartments.reduce((prev, apartment, index) => {
        // Copy all existing floors to prev
        if (index === 0) {
          prev = floorsFromBBRBuildings
        }

        // Find existing floor
        const existingFloor = prev.find(
          (x) => x.data.bbrBuildingID === mainBuilding.bbrID
            && x.data.numericFloorValue === apartment.data.numericFloorValue,
        )

        // Create floor if not existing
        if (existingFloor) {
          apartment.data.floorID = existingFloor.id
        }
        else {
          const floor = new Floor({})

          floor.data.buildingID = mainBuilding.id
          floor.data.bbrBuildingID = mainBuilding.bbrID
          floor.data.numericFloorValue = apartment.data.numericFloorValue

          apartment.data.floorID = floor.id

          prev.push(floor)
        }
        return prev
      }, [])

      return allBBRFloors
    },
    connectFloorsAndApartmentsToBuildings(
      allBBRBuildings,
      allBBRApartments,
      allBBRFloors,
    ) {
      allBBRBuildings = allBBRBuildings.reduce((prev, building) => {
        // Get floor ids
        building.floorIDs = allBBRFloors.reduce((prev2, floor) => {
          if (floor.data.buildingID === building.id) {
            prev2.push(floor.id)
          }
          return prev2
        }, [])

        // Get apartment ids
        building.apartmentIDs = allBBRApartments.reduce((prev3, apartment) => {
          if (apartment.data.buildingID === building.id) {
            prev3.push(apartment.id)
          }
          return prev3
        }, [])
        prev.push(building)
        return prev
      }, [])

      return allBBRBuildings
    },
    connectApartmentsToFloors(allBBRApartments, allBBRFloors) {
      allBBRFloors = allBBRFloors.reduce((prev, floor) => {
        floor.apartmentIDs = allBBRApartments.reduce((prev2, apartment) => {
          if (apartment.data.floorID === floor.id) {
            prev2.push(apartment.id)
          }
          return prev2
        }, [])
        prev.push(floor)
        return prev
      }, [])
      return allBBRFloors
    },
    async buildInitialDataFromBBR() {
      // Disclaimers
      // - We assume that only 1 building per access address can have a hallway with apartments
      // - Should more than 1 building have a hallway we will try and find the corrent one
      // based on their status

      // Get start data
      const {
        addressBuildings,
        addressEntrances,
        addressData,
      } = await this.getStartDataFromDAWA()

      let allBBRBuildings = await this.getAllBBRBuildingsWithData(
        addressBuildings,
        addressEntrances,
      )

      // Check for any buildings
      if (!allBBRBuildings.length) {
        this.$emit('no-units-found')
        return
      }

      const allBBRApartments = this.getAllBBRApartments(addressData, allBBRBuildings)
      const floorsFromBBRBuildings = this.getFloorsBasedOnBBRBuildings(allBBRBuildings)
      let allBBRFloors = this.getAllFloorsAndAssignApartmentsToFloors(
        allBBRBuildings,
        allBBRApartments,
        floorsFromBBRBuildings,
      )
      allBBRBuildings = this.connectFloorsAndApartmentsToBuildings(
        allBBRBuildings,
        allBBRApartments,
        allBBRFloors,
      )
      allBBRFloors = this.connectApartmentsToFloors(allBBRApartments, allBBRFloors)
      const combinedList = allBBRBuildings.concat(allBBRApartments, allBBRFloors)

      this.$store.dispatch('saveScreeningUnits', { combinedList })
    },
  },
  components: { Circular },
  created() {
    this.buildInitialDataFromBBR()
  },
}
</script>

<style lang="stylus" scoped>
  .GetBBRData
    box(300px)
    max-width 100%
    flex-center-children-column()
    justify-content center
    margin 0 auto
    .LoadingWrap
      box(60px)
      position relative
      margin-bottom 15px
</style>
