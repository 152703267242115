<template>
  <div class="ScreeningsUpdateBuildings">
    <CloseButton />

    <div class="NormalPageTitle">
      {{ mixWB('UNITS_FOR_SCREENING') }}
    </div>

    <p>{{ mixWB('SELECT_WHAT_TO_BE_MAPPED_TEXT') }}</p>
    <p v-if="currentScreeningData.isStarted">
      {{ mixWB('ONLY_POSSIBLE_TO_ADD_UNITS_TEXT') }}
    </p>

    <div
      class="TooOldWrap"
      v-if="screeningIsTooOld">
      <span>{{ mixWB('PAGE_CANNOT_BE_SHOWN_AS_SCREENING_IS_TOO_OLD_TEXT') }}</span>
    </div>

    <GetBBRData
      v-if="isLoadingDataFromBBR"
      @no-units-found="onAllUnitsSaved" />

    <ScreeningUnitSelection
      v-if="!isLoadingDataFromBBR"
      :loadSavedUnits="loadSavedUnitsCounter" />

    <CadastralMapAutoUpload v-if="!isLoadingDataFromBBR" />
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import GetBBRData from '@/components/ScreeningItems/GetBBRData.vue'
import ScreeningUnitSelection from '@/components/ScreeningItems/ScreeningUnitSelection.vue'
import CadastralMapAutoUpload from '@/components/ScreeningItems/CadastralMapAutoUpload.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'ScreeningsUpdateBuildings',
  data() {
    return {
      screeningIsTooOld: false,
      isLoadingDataFromBBR: false,
      loadSavedUnitsCounter: 0,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'screeningUnits',
    ]),
  },
  methods: {
    onAllUnitsSaved() {
      this.isLoadingDataFromBBR = false
      requestAnimationFrame(() => {
        this.loadSavedUnitsCounter += 1
      })
    },
    checkVersion() {
      const allGood = this.mixCompareVersionsSameOrNewer({
        versionA: this.currentScreeningData.appVersion,
        versionB: '1.13.0',
      })

      this.screeningIsTooOld = !allGood
    },
  },
  components: {
    CloseButton,
    GetBBRData,
    ScreeningUnitSelection,
    CadastralMapAutoUpload,
  },
  created() {
    EventBus.$on('all-units-saved', this.onAllUnitsSaved)
    this.checkVersion()

    if (this.screeningIsTooOld) {
      return
    }

    if (this.currentScreeningData.hasSavedBBRUnits) {
      this.showSavedUnits = true
    }
    else {
      this.isLoadingDataFromBBR = true
    }
  },
  destroyed() {
    EventBus.$off('all-units-saved', this.onAllUnitsSaved)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateBuildings
    pageWrap()

  .TooOldWrap
    flex-center-children-column()
    justify-content center
    margin 0 auto
    box(300px)
    max-width 100%
    span
      text-align center

  .LoadingDataFromBBR
    box(300px)
    max-width 100%
    flex-center-children-column()
    justify-content center
    margin 0 auto
    .LoadingWrap
      box(60px)
      position relative
      margin-bottom 15px

</style>
