<template>
  <div class="FloorSelectMenu">
    <DotMenuItem
      :text="mixWB('EDIT_FLOOR')"
      itemID="edit-floor"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      v-if="canBeDeleted"
      :text="mixWB('DELETE_FLOOR')"
      color="red"
      itemID="delete-floor"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'

export default {
  name: 'FloorSelectMenu',
  props: {
    canBeDeleted: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'menu-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
</style>
