<template>
  <div class="ApartmentSelectItem">
    <div
      class="ApartmentWrap"
      @click="onApartmentClick">
      <div
        class="CheckboxWrap">
        <div
          :class="{
            'IsSelected': apartment.isSelected,
          }"
          class="CheckBox">
          <CheckIcon class="CheckIcon" />
        </div>
      </div>
      <div
        class="InfoWrap">
        <span class="ApartmentTitle">{{ apartment.getTitle() }}</span>
        <span>{{ mixWB('STATUS') }}: {{ apartment.getStatus() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import EventBus from '@/EventBus'

export default {
  name: 'ApartmentSelectItem',
  props: {
    apartment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onApartmentClick() {
      EventBus.$emit('apartment-click', this.apartment.id)
    },
  },
  components: {
    CheckIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .ApartmentSelectItem
    padding 0px 10px 10px 0px
    &:last-child
      padding-bottom 0

  .ApartmentWrap
    display flex
    cursor pointer
    .CheckboxWrap
      flex-center-children()
      .CheckBox
        box(26px)
        padding 2px
        margin-right 10px
        background-color #fff
        border 1px solid #777
        .CheckIcon
          display none
        &.IsSelected
          border 1px solid $color_primary
          .CheckIcon
            display block
            fill $color_primary
    .InfoWrap
      span
        display block
        font-size 0.875rem
      .ApartmentTitle
        font-size 1rem
        text-transform uppercase
        font-weight bold
</style>
